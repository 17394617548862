<div class="bg-login d-flex justify-content-center align-items-center">
    <div class="d-flex">
        <div class="loginExtraSection p-4">
            <h6>{{ 'LOGIN.EXTRA_TITLE' | translate }}</h6>
            <p class="darkText">{{ 'LOGIN.EXTRA_SUBTITLE' | translate }}</p>
        </div>

        <div class="loginMainSection p-4">
            <h3 class="title3 text-center text-white mb-3">{{ 'LOGIN.TITLE' | translate }}</h3>

            <form [formGroup]="form" (ngSubmit)="submit()">
                <mat-form-field appearance="outline" class="customMatFormField mb-3">
                    <mat-label>{{ 'LOGIN.USERNAME' | translate }}</mat-label>
                    <input type="text" matInput (keydown)="deleteError()" formControlName="username" autocomplete="off">
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField mb-4">
                    <mat-label>{{ 'LOGIN.PASSWORD' | translate }}</mat-label>
                    <input [type]="hide ? 'password' : 'text'" matInput formControlName="password"
                        (keydown)="deleteError()" autocomplete="off">
                    <i class="passwordIcon" matSuffix
                        [ngClass]="hide ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'"
                        (click)="hide = !hide"></i>
                </mat-form-field>

                <mat-error *ngIf="error" class="mb-3">
                    {{ error }}
                </mat-error>

                <div class="custom-button d-flex flex-column justify-content-center">
                    <a class="linkText" routerLink="../forgot-password" class="text-right mb-3">{{ 'LOGIN.FORGOT' | translate }}</a>
                    <button type="submit" mat-raised-button>{{ 'LOGIN.LOGIN' | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>