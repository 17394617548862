import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Domain } from '../../shared/models/domain';
import { ChangePasswordRequest } from '../../shared/models/changePasswordRequest';
import { AuthSubscriptionsService } from '../../shared/services/auth-subscriptions/auth-subscriptions.service';
import * as AuthActions from '../../store/auth/auth.actions';
import { AuthState } from '../../store/auth/auth.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Component({
  selector: 'urban-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {

  @HostBinding('class') className = 'authMode';
  public appVersion: string = environment.appVersion;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  constructor(private authService: AuthSubscriptionsService, private store: Store<AuthState>) { }

  ngOnInit(): void {
    this.authService.getSetErrorComand().pipe(takeUntil(this.ngUnsubscribe)).subscribe(target => {
      if (target) this.setError(target)
    });
    this.authService.getRequestLoginComand().pipe(takeUntil(this.ngUnsubscribe)).subscribe(target => {
      if (target) this.requestLogin(target)
    });
    this.authService.getInitSetUserDomainComand().pipe(takeUntil(this.ngUnsubscribe)).subscribe(target => {
      if (target) this.initSetUserDomain(target)
    });

    this.authService.getChangePasswordComand().pipe(takeUntil(this.ngUnsubscribe)).subscribe(target => {
      if (target) this.changePassword(target)
    });
  }

  public requestLogin(bodyLogin: { username: string, password: string }): void {
    this.store.dispatch(AuthActions.requestLogin(bodyLogin));
  }

  public setError(errorToSet: { error: string }): void {
    this.store.dispatch(AuthActions.setError(errorToSet));
  }

  public initSetUserDomain(domain: Domain): void {
    this.store.dispatch(AuthActions.initSetUserDomain(domain));
  }

  public changePassword(changePasswordRequest: ChangePasswordRequest): void {
    this.store.dispatch(AuthActions.changePassword({ changePasswordRequest }))
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
