<div class="bg-login">
    <div *ngIf="stepper === 0">
        <div class="forgotExtraSection d-flex flex-column p-4" *ngIf="!isLittleScreen">
            <h6>{{ 'FORGOT.EXTRA_TITLE' | translate }}</h6>
            <p class="darkText">{{ 'FORGOT.EXTRA_SUBTITLE' | translate }}</p>
        </div>
        
        <div class="forgotMainSection p-4">
            <h3 class="title3 mb-3 text-white">{{ 'FORGOT.TITLE' | translate}}</h3>
            <p *ngIf="isLittleScreen">{{ 'FORGOT.EXTRA_SUBTITLE' | translate }}</p>

            <form [formGroup]="form" (ngSubmit)="submit()">
                <mat-form-field appearance="outline" class="customMatFormField mb-3">
                    <mat-label>{{ 'FORGOT.MAIL' | translate }}</mat-label>
                    <input type="email" matInput formControlName="mail" autocomplete="off">
                </mat-form-field>

                <mat-error *ngIf="errorInRequest">
                    {{ 'ERROR.BAD_REQUEST' | translate }}
                </mat-error>
                <mat-error *ngIf="!this.correctInput">
                    {{ 'ERROR.WRONG_CREDENTIALS' | translate }}
                </mat-error>

                <div class="custom-button d-flex justify-content-between">
                    <button mat-button type="button" class="mat-primary" routerLink="../login">{{ 'GENERAL.BACK' | translate }}</button>
                    <button id="sendButton" mat-button type="submit" [disabled]="isLoading">
                        <span class="material-icons rotating" *ngIf="isLoading">
                            loop
                        </span>
                        {{ 'FORGOT.SEND' | translate }}
                    </button>
                </div>
            </form>
        </div>
    </div>
    
    <div class="checkCard" *ngIf="stepper === 1">
        <p class="mb-3 text-white">{{ 'FORGOT.CHECK' | translate }}</p>

        <button mat-button type="button" class="mat-primary" routerLink="../login">{{ 'GENERAL.BACK' | translate }}</button>
    </div>
</div>