<div class="bg-login d-flex justify-content-center align-items-center">
    <div class="d-flex">
        <div class="recoveryMainSection p-4">
            <h3 class="title3 text-white text-center mb-3">{{ 'CONFIRM_DELETE.TITLE' | translate }}</h3>

            <p *ngIf="success" class="success paragraphText text-center">
                {{ success }}
            </p>
        </div>

        <div class="recoveryExtraSection p-4 d-flex flex-column" *ngIf="!isLittleScreen">
            <h6>{{ 'CONFIRM_DELETE.EXTRA_TITLE' | translate }}</h6>
            <p class="darkText">{{ 'CONFIRM_DELETE.EXTRA_SUBTITLE' | translate }}</p>
        </div>
    </div>
</div>
