import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api.service';

@Component({
  selector: 'urban-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public correctInput: boolean = true;
  public errorInRequest: boolean = false;
  public stepper: number = 0;
  public isLoading: boolean = false;
  public isLittleScreen: boolean;
  private ngUnsubscribe: Subject<void> = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (event.target.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  constructor(private apiService: ApiService, private router: Router, private fb: UntypedFormBuilder) {
    if (window.innerWidth > 800) {
      this.isLittleScreen = false;
    } else {
      this.isLittleScreen = true;
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      mail: new UntypedFormControl('', Validators.email),
    })
  }

  //ATTENZIONE: da correggere il controllo sul Backend dell'esistenza della mail in input nel database
  submit() {
    this.errorInRequest = false
    this.correctInput = this.form.valid;
    if (this.form.valid) {
      this.isLoading = true;
      this.apiService.findEmailToResetPassword(this.form.get('mail').value)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          (key: any) => {
            setTimeout(() => {
              this.isLoading = false;
              this.stepper = 1;
            }, 2000);
          },
          err => {
            setTimeout(() => {
              this.isLoading = false;
              this.errorInRequest = true
            }, 2000);
          }
      );
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

