<div class="bg-login d-flex justify-content-center align-items-center">
    <div class="d-flex">
        <div class="recoveryMainSection p-4">
            <h3 class="title3 text-center text-white mb-3">{{ 'RECOVERY.TITLE' | translate }}</h3>

            <form [formGroup]="form" (ngSubmit)="submit()">
                <mat-form-field appearance="outline" class="customMatFormField mb-3">
                    <mat-label>{{ 'RECOVERY.NEW_PASSWORD' | translate }}</mat-label>
                    <input [type]="hideNewPassword ? 'password' : 'text'" matInput formControlName="newPassword">
                    <i class="passwordIcon" [ngClass]="hideNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" matSuffix (click)="hideNewPassword = !hideNewPassword"></i>
                </mat-form-field>

                <mat-form-field appearance="outline" class="customMatFormField mb-3">
                    <mat-label>{{ 'RECOVERY.CONFIRMED_NEW_PASSWORD' | translate }}</mat-label>
                    <input [type]="hideConfirmedNewPassword ? 'password' : 'text'" matInput formControlName="confirmedNewPassword">
                    <i class="passwordIcon" [ngClass]="hideConfirmedNewPassword ? 'urbanicon-visibility-off-outline' : 'urbanicon-visibility-outline'" matSuffix (click)="hideConfirmedNewPassword = !hideConfirmedNewPassword"></i>
                </mat-form-field>

                <mat-error *ngIf="error && error !== undefined && error !== ''" class="error">
                    {{ error }}
                </mat-error>

                <p *ngIf="success" class="success">
                    {{ 'RECOVERY.' + success | translate  }}
                </p>

                <div class="custom-button d-flex justify-content-between p-4">
                    <button mat-button type="button" class="mat-primary" routerLink="../login">{{ 'GENERAL.BACK' | translate }}</button>
                    <button id="confirmButton" mat-button type="submit">{{ 'RECOVERY.CONFIRM' | translate }}</button>
                </div>
            </form>>
        </div>

        <div class="recoveryExtraSection p-4 d-flex flex-column" *ngIf="!isLittleScreen">
            <h6>{{ 'RECOVERY.EXTRA_TITLE' | translate }}</h6>
            <p class="darkText">{{ 'RECOVERY.EXTRA_SUBTITLE' | translate }}</p>
        </div>
    </div>
</div>
