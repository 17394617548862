import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthComponent } from '../../pages/auth/auth.component';
import { LoginComponent } from '../../pages/auth/login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthEffects } from '../../store/auth/auth.effects';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import * as fromAuth from '../../store/auth/auth.reducer';
import { ForgotComponent } from '../../pages/auth/forgot/forgot.component';
import { RecoveryComponent } from '../../pages/auth/recovery/recovery.component';
import { ResetExpiredComponent } from '../../pages/auth/reset-expired/reset-expired.component';
import { ActivateComponent } from '../../pages/auth/activate/activate.component';
import {ConfirmDeleteComponent} from '../../pages/auth/confirm-delete/confirm-delete.component';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';



@NgModule({
    declarations: 
    [
        AuthComponent,
        LoginComponent,
        ForgotComponent,
        RecoveryComponent,
        ResetExpiredComponent,
        ActivateComponent,
        ConfirmDeleteComponent
    ], 
    imports: 
    [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        AuthRoutingModule,
        SharedModule,
        TranslateModule,
        StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.authReducer),
        EffectsModule.forFeature([AuthEffects])], providers: [provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AuthModule { }
